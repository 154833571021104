import React, { useEffect, useState, useContext } from "react";
import { message, Button, Select, Form, Divider, Row, Col } from "antd";
import { getAuthHeaders, logoutUser } from "../helpers/authHelper";
import { Context } from "../context/store";
import { baseAPIUrl } from "../constants/urlConstants";
import ACTIONS from "../context/actions";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

export const SignoffList = (props) => {
  const [form] = Form.useForm();
  const [signoffs, setSignoffs] = useState([<div>loading</div>]);
  const [submitting, setSubmitting] = useState(false);
  const [newData, setNewData] = useState(false);

  const [state, dispatch] = useContext(Context);

  //use effect to listen for submit data event
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submitData]);

  //load signoffs for the first time
  useEffect(() => {
    clearSignoffs();
    if (state.ticket.id) {
      loadSignoffs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ticket.ticketLoadTime]);

  const checkNewData = (changedValues, allValues) => {
    let isThereNewData = false;
    console.log("ALL: \n");

    console.log(allValues);
    console.log("Changed:\n");
    console.log(changedValues);
    for (let signoffID in allValues) {
      let employeeID = allValues[signoffID];
      signoffID = signoffID.split("*")[0]; //get the actual signoff id split from the ticket id
      const matchingSignoff = signoffs.find((curSignoff) => {
        return curSignoff.signoffID === Number(signoffID);
      });

      //if employyee id is undefined make it null for comp
      if (employeeID === undefined) {
        employeeID = null;
      }

      //check if theres a change
      if (employeeID !== matchingSignoff.employeeID) {
        isThereNewData = true;
      }
    }
    setNewData(isThereNewData);
  };

  const clearSignoffs = () => {
    setSignoffs([<div>loading</div>]);
    setSubmitting(false);
    setNewData(false);
  };

  const loadSignoffs = () => {
    clearSignoffs();
    fetch(
      baseAPIUrl +
        `signoffs/currentsignoffs/?id=${state.ticket.id}&stage=${state.ticket.stage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          user_key: state.key,
        },
      }
    )
      .then((res) => {
        //check if need to login again
        if (res.status === 401) {
          message.info("Please Login Again!");
          logoutUser();
          dispatch({ type: ACTIONS.CLEAR_USER_DETAILS });
          return false;
        }
        return res.json();
      })
      .then((res) => {
        setSignoffs(res);
      });
  };

  const onSubmitSignoffs = (values) => {
    const signoffPostObject = { employeeSignoffs: [] };
    //map values into the object to send to server
    for (const signoff in values) {
      const employeeIDs = values[signoff];
      const signoffID = signoff.split("*")[0];
      if (employeeIDs?.length > 0 && signoffs) {
        for (let i = 0; i < employeeIDs.length; i++) {
          signoffPostObject.employeeSignoffs.push({
            signoffID,
            employeeID: Number(employeeIDs[i]),
          });
        }
      }
    }

    setSubmitting(true);
    fetch(baseAPIUrl + "signoffs/employeesignoffs?id=" + state.ticket.id, {
      method: "POST",
      body: JSON.stringify(signoffPostObject),
      headers: {
        "Content-Type": "application/json",
        user_key: state.key,
      },
    })
      .then(
        (res) => {
          return res.json();
        },
        (error) => {
          setSubmitting(false);
          message.error("Error response submitting signoffs");
          console.error(error);
        }
      )
      .then((results) => {
        setSubmitting(false);
        if (results.result === "Success") {
          loadSignoffs();
          message.success("Submitted Employee Signoffs");
          setNewData(false);
        } else {
          message.error("Error submitting signoffs");
          console.error("error submitting some or all signoffs");
          console.error(results);
        }
      });
  };

  const onDelete = (signoffID) => {
    fetch(
      `${baseAPIUrl}signoffs/employeesignoffs?ticketid=${state.ticket.id}&signoffid=${signoffID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          user_key: state.key,
        },
      }
    )
      .then(
        (res) => {
          return res.text();
        },
        (error) => {
          setSubmitting(false);
          message.error("Error response deleting signoffs");
          console.error(error);
        }
      )
      .then((result) => {
        loadSignoffs();
        setSubmitting(false);
        if (result === "Deleted employee signoff") {
          form.setFieldValue(signoffID, null);
          message.success(result);
        } else {
          message.error(result);
          console.error("error deleting signoff");
          console.error(result);
        }
      });
  };

  if (signoffs.length > 0) {
    return (
      <div key="signofflist">
        <Divider>Employee Signoffs</Divider>
        <Form
          layout="horizontal"
          name="employee_signoff_logger"
          className="employee_signoff_logger"
          initialValues={{ remember: true }}
          scrollToFirstError
          form={form}
          onFinish={onSubmitSignoffs}
          onValuesChange={checkNewData}
        >
          {signoffs.map((signoff, i) => {
            return (
              <Row key={i}>
                <Col span={22}>
                  <Form.Item
                    label={signoff.signoff + " by"}
                    name={signoff.signoffID + "*" + state.ticket.id}
                  >
                    <Select
                      showSearch
                      placeholder="Employee"
                      disabled={signoff.employeeIDs?.length}
                      defaultValue={signoff.employeeIDs}
                      mode="multiple"
                      allowClear={false}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {state.employees.filter(employee => !employee.deleted).map((employee) => (
                        <Option
                          value={employee.id}
                        >{`${employee.name}`}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    hidden={!signoff.employeeIDs?.length}
                    style={{ marginLeft: "5px" }}
                    onClick={() => onDelete(signoff.signoffID)}
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Col>
              </Row>
            );
          })}
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-ticket-button"
              loading={submitting}
              disabled={!newData}
            >
              Submit Signoffs
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return <div></div>;
};
